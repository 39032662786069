.designContainer {
  margin: 12vw 4vw 0vw 4vw;
  margin-top: 20vw;
  font-size: 1rem;
}

a {
  color: black;
}

#webLink:hover {
  text-decoration-line: underline;
  text-decoration-style: wavy;
}

#dumpling {
  font-size: 10px;
}

#designText {
  width: 75%;
  text-align: justify;
}

.grid {
  margin-bottom: 3vw;
}

.screengrab {
  object-fit: contain;
  width: 100%;
}

.box1 {
  border: solid 1px lightgrey;
  margin-bottom: 3vw;
  padding: 1vw;
}

.textBox {
  padding: 4vw;
  font-size: 1rem;
}

@media only screen and (min-width: 600px) {
  .designContainer {
    margin: 4vw 0vw 0vw 4vw;
    margin-top: 10vw;
    font-size: 1rem;
  }

  #designText {
    width: 55%;
    margin-bottom: 5vw;
  }

  .box1 {
    display: flex;
    justify-content: space-between;
    margin-right: 4vw;
  }

  .textBox {
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .screengrab {
    object-fit: contain;
    width: 25%;
    padding: 0.5vw;
  }
}
