@font-face {
  font-family: "dinlight";
  src: url("./fonts/DIN-Light.eot");
  src: url("./fonts/din_light_regular-webfont.woff2") format("woff2"),
    url("./fonts/din_light_regular-webfont.woff") format("woff"),
    url("./fonts/DIN-Light.eot?#iefix") format("eot"),
    url("./fonts/DIN-Light.woff") format("woff"),
    url("./fonts/DIN-Light.ttf") format("truetype"),
    url("./fonts/DIN-Light.svg#DIN-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import url(//db.onlinewebfonts.com/c/cbf9b417fc15851ba870990e585e35a2?family=DIN);

body {
  margin: 0;
  font-family: "dinlight", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: white;
  overflow: hidden auto;
}
