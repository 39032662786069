h1 {
  margin-block-end: 1vw;
}

a {
  text-decoration: none;
}

.welcomeText {
  width: 75%;
}

.aboutText {
  display: flex;
  flex-direction: column;
  margin-top: 8vw;
}

.aboutText a:hover {
  color: #000000;
  text-decoration-line: underline;
  text-decoration-style: wavy;
}

.aboutText a {
  color: #000000;
  text-decoration: none;
}

.aboutLines {
  padding-bottom: 4vh;
}

.social {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin-top: 6vw;
}

.social a #icon {
  width: 4vh;
  height: 4vh;
  padding: 2vw;
  text-align: center;
  line-height: 32px;
  border-radius: 32px;
  display: block;
  background-color: black;
  color: white;
  font-size: 12px;
}

#cvButton img {
  width: 12.5vw;
}

#cvIcon:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.social > a {
  margin-right: 4vw;
}

.social a #icon:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

@media only screen and (min-width: 600px) {
  #cvButton img {
    width: 3vw;
  }

  .aboutText {
    margin-top: 3vw;
  }

  .welcomeText {
    width: 55%;
  }

  .social {
    margin-top: 2vw;
  }

  .social > a > #icon {
    width: 3vh;
    height: 3vh;
    padding: 9px;
  }

  .social > a {
    margin-right: 2vw;
  }
}
